/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('public.activate', {
      url: '/activate',
      templateUrl:
        'sections/activation/templates/unauthed-activation-layout.ng.html',
      controller: 'acpSectionActivationCtrl',
      data: {
        permissions: {
          only: 'password_unauthed',
          except: 'isVariantSunset',
          redirectTo: {
            password_unauthed: 'activate-additional',
            isVariantSunset: 'sunset-tenant.activate'
          }
        }
      },
      resolve: {
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('sections/activation')
          );
        },
        pageH1: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('sections/activation', 'page-h1')
          );
        },
        pageMetaDescription: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle(
              'sections/activation',
              'meta-description'
            )
          );
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.activation" */
            'sections/activation'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    // Used as a state redirect
    .state('activate', {
      redirectTo: 'public.activate'
    })
    .state('activate-additional', {
      parent: 'root',
      url: '/activate/additional-card',
      templateUrl:
        'sections/activation/templates/authed-activation-layout.ng.html',
      data: {
        permissions: {
          except: 'hub_user',
          redirectTo: {
            hub_user: 'activate-add-a-card'
          }
        }
      },
      params: {
        cardId: null
      },
      resolve: {
        pageTitle: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle(
              'sections/activation',
              'page-title.additional'
            )
          );
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.activation" */
            'sections/activation'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('activate-add-a-card', {
      parent: 'authenticated',
      url: '/activate/add-a-card',
      template:
        '<acp-add-a-card-activation-section></acp-add-a-card-activation-section>',
      data: {
        permissions: {
          only: 'hub_user',
          redirectTo: {
            hub_user: 'activate-additional'
          }
        }
      },
      resolve: {
        pageTitle: /* @ngInject */ function (module, $injector) {
          // jshint ignore:line
          return $injector.invoke(
            nsUtilProvider.getPageTitle(
              'sections/activation',
              'title-activate-add-a-card'
            )
          );
        },
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.activation" */
            'sections/activation'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    // Only used as a state redirect
    .state('register', {
      redirectTo: 'public.register',
      template: ''
    })
    .state('public.register', {
      url: '/register',
      templateUrl:
        'sections/activation/templates/unauthed-activation-layout.ng.html',
      controller: 'acpSectionActivationCtrl',
      data: {
        permissions: {
          except: 'isVariantSunset',
          redirectTo: {
            isVariantSunset: 'sunset-tenant.register'
          }
        }
      },
      params: {
        backButtonEnabled: null
      },
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.activation" */
            'sections/activation'
          ).then($ocLazyLoad.loadModule);
        },
        $fingerPrint: /* @ngInject */ function (
          $q,
          $location,
          acpConfigurationService
        ) {
          return $q(function (resolve) {
            var hash = $location.hash();

            if (hash && hash !== '') {
              acpConfigurationService.setOneTimeUseToken(hash);
            }

            resolve();
          });
        }
      }
    })
    .state('activate-handoff', {
      controller: /* @ngInject */ function (
        activationHandoffService,
        cardActivationModel,
        $state
      ) {
        // We want to hydrate our model prior to initializing the activation
        // sub-router and components
        var handoffState = activationHandoffService.getState();
        if (handoffState) {
          cardActivationModel.hydrate(handoffState);
        }
        $state.go('public.activate');
      },
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.activation" */
            'sections/activation'
          ).then($ocLazyLoad.loadModule);
        }
      }
    });
}

export default states;
